/* src/pages/Forbidden.css */
.forbidden-container {
    text-align: center;
    padding: 50px;
    color: #333;
  }
  
  .forbidden-container h1 {
    font-size: 3rem;
    color: #e63946;
  }
  
  .forbidden-container p {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  .back-home-link {
    margin-top: 20px;
    display: inline-block;
    font-size: 1.2rem;
    color: #0077b6;
    text-decoration: none;
    border: 2px solid #0077b6;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .back-home-link:hover {
    background-color: #0077b6;
    color: white;
  }
  