:root {
    --primary-color: #005f73;
    --hover-color: rgb(42, 41, 41);
    --light-background: #f4f4f9;
    --text-color: white;
    --border-color: #ccc;
}

/* General container styling */
.cybersecurity-basics-container {
    padding: 1rem;
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin: 1rem auto;
    max-width: 1000px;
    width: 100%; /* Ensure it scales for different screen sizes */
    text-align: left;
    position: relative;
}

/* Header styling */
.cybersecurity-basics-header {
    text-align: center;
    margin-bottom: 1rem;
}

.cybersecurity-basics-header h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    margin-top: 3rem;
}

/* Button and audio controls */
.audio-controls {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 0rem; /* Adjusted for better spacing */
}

.audio-controls button {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.audio-controls button:hover {
    background-color: var(--hover-color);
}

/* Content with borders */
.bordered-container {
    border: 0px solid black;
    padding: 0.75rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    background-color: white;
}

.bordered-container.alt-color {
    background-color: white;
}

.module-section {
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.module-content h3 {
    font-size: 1.25rem;
    color: black;
    margin-bottom: 0.5rem; /* Added margin for better spacing */
}

.module-content p {
    font-size: 1.3rem;
    color: black;
    margin-bottom: 0;
}

/* Images */
.introduction-image, .quiz-image {
    width: 100%;
    max-width: 40%;
    height: auto;
    margin: 1rem auto;
    display: block;
    border-radius: 8px;
}

/* Flex layout for content */
.content-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.content-layout .module-content {
    flex: 1;
    margin-right: 1rem;
}

.content-with-image {
    display: flex;
    align-items: center;
}

.bordered-paragraph {
    border: 2px solid black;
    padding: 0.75rem;
    border-radius: 8px;
    text-align: left;
    flex: 1;
}

/* Highlighted section */
.highlighted-section {
    border: 0px solid black;
    padding: 0.75rem;
    border-radius: 8px;
    margin-top: 0.75rem;
    margin-bottom: 0rem;
    background-color: white;
}

.highlighted-section h3 {
    margin-bottom: 1rem;
    font-size: 1.25rem; /* Slightly larger for better visibility */
    color: black;
}

.highlighted-section ul {
    padding-left: 1.5rem; /* Ensure bullet points are properly indented */
    margin: 0;
}

.highlighted-section li {
    margin-bottom: 0.75rem; /* Add spacing between bullet points */
    color: black; /* Ensure bullet points are visible on dark background */
}

/* Button styles */
.start-quiz-button, .next-quiz-button, .quiz-section button {
    display: block;
    width: 100%;
    max-width: 300px;
    padding: 0.75rem;
    background-color: rgb(201, 3, 3);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin: 0.75rem auto;
}

.start-quiz-button:hover, .next-quiz-button:hover, .quiz-section button:hover {
    background-color: rgb(218, 70, 70);
}

/* Quiz section */
.quiz-section {
    margin-top: 0.75rem;
    background-color: var(--light-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
}

.correct, .incorrect {
    font-weight: bold;
}

.correct {
    color: green;
}

.incorrect {
    color: rgb(201, 3, 3);
}

/* General back button styling */
.back-button {
    padding: 0.5rem 1rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    position: relative; /* For small screens */
    display: inline-block;
    margin: 1rem 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 65px;
}

.back-button:hover {
    background-color: rgb(66, 66, 66);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .cybersecurity-basics-container {
        width: 95%;
    }

    .module-content h3 {
        font-size: 1.1rem;
    }

    .start-quiz-button {
        font-size: 0.875rem;
    }

    .back-button {
        font-size: 0.875rem;
    }

    .content-layout, .content-with-image {
        flex-direction: column;
    }

    .introduction-image, .quiz-image {
        width: 100%;
        margin-bottom: 1rem;
    }

    .highlighted-section h3 {
        font-size: 1.1rem; /* Slightly smaller for smaller screens */
    }
}

@media (max-width: 480px) {
    .cybersecurity-basics-header h2 {
        font-size: 1.5rem;
    }

    .module-content h3 {
        font-size: 1rem;
    }

    .module-content p {
        font-size: 0.9rem;
    }

    .start-quiz-button, .back-button {
        font-size: 0.875rem;
        padding: 0.5rem;
    }

    .highlighted-section h3 {
        font-size: 1rem;
    }
}

.white-background-container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .sms-example-box {
    margin-bottom: 20px;
  }
  
  .answer-buttons button {
    margin-right: 10px;
  }
  
  .feedback {
    margin-top: 10px;
  }
  
  .correct {
    color: green;
  }
  
  .incorrect {
    color: rgb(201, 3, 3);
  }
  .tooltip {
    position: relative;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; 
    left: 50%;
    margin-left: -110px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip:hover .tooltiptext,
  .tooltip:focus .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .back-arrow-button, .start-quiz-button {
    cursor: pointer;
   
  }
  
  