:root {
    --primary-color: #005f73;
    --hover-color: #0a9396;
    --light-background: #f4f4f9;
    --text-color: white;
    --border-color: #ccc;
}
/* General container styling */
.cybersecurity-basics-container-threat-landscape-statistics {
    padding: 1rem;
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin: 1rem auto;
    max-width: 1000px;
    width: 100%; /* Ensure it scales for different screen sizes */
    text-align: left;
    position: relative;
  }
  
  .module-section-threat-landscape-statistics {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px;
  }

 
  .video-container2 {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .video-container2 video {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
  
  .inline-content-threat-landscape-statistics {
    text-align: center;
    margin-bottom: 40px;
    border: 1px solid #ddd;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .image-source-threat-landscape-statistics {
    color: black !important;
  }
  
  .image-source-threat-landscape-statistics a {
    color: black !important; /* Ensure the link is also black */
  }
  
  .inline-content-threat-landscape-statistics h2 {
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
  }
  
  .inline-content-threat-landscape-statistics h4 {
    color: black !important;
  }
  
  .divider-threat-landscape-statistics {
    height: 1px;
    background-color: #ccc;
    margin: 40px 0;
    width: 100%;
  }
  
  /* Image styling */
  .small-image-threat-landscape-statistics {
    margin-bottom: 20px;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-source-threat-landscape-statistics {
    text-align: center;
    font-style: italic;
    font-size: 14px;
    color: #666;
    margin-bottom: 40px;
  }
  
  .image-source-threat-landscape-statistics a {
    color: #007bff;
    text-decoration: underline;
  }
  
  /* Bordered container styling */
  .bordered-container-threat-landscape-statistics {
    padding: 30px;
    margin-bottom: 50px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .bordered-container-threat-landscape-statistics ul {
    font-size: 16px;
    line-height: 1.7;
  }
  
  .bordered-container-threat-landscape-statistics li {
    margin-bottom: 15px;
  }
  
  /* Figure heading */
  .figure-heading-threat-landscape-statistics {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(249, 67, 47);
    font-size: 20px;
  }
  
  /* Button styling */
  .start-quiz-button-container-threat-landscape-statistics {
    text-align: center;
    margin-top: 40px;
  }
  
  .next-button-threat-landscape-statistics {
    background-color: rgb(201, 3, 3);
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .next-button-threat-landscape-statistics:hover {
    background-color: rgb(218, 70, 70);
  }
  
  /* Centered link styling */
  .centered-link-threat-landscape-statistics {
    text-align: center;
    padding: 20px;
    color: black;
    margin-top: 40px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .centered-link-threat-landscape-statistics a {
    color: #007bff;
    text-decoration: underline;
  }
  