.Tabs {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .tab-buttons {
    margin-top: 2rem;
    display: flex;
    border-bottom: 2px solid #ddd;
  }
  
  .tab-button {
    flex: 1;
    padding: 15px;
    border: none;
    background-color: #f8f8f8;
    font-size: 16px;
    font-weight: bold;
    color: black;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
    margin-bottom: 1rem;
  }
  
  .tab-button:hover {
    background-color: #eaeaea;
  }
  
  .tab-button.active {
    background-color: white;
    border: 3px solid grey;
  
    color: rgb(201, 3, 3);
  }
  
  .tab-content {
    padding: 20px;
    color: rgb(94, 93, 93);
    background-color: white;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  