.password-game-container-unique {
    padding: 2rem;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin: 2rem auto;
    max-width: 800px;
    text-align: center;
    position: relative;
  }
  
  .back-button-unique {
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-right: 55rem;
  }
  
  .back-button-unique:hover {
    background-color: rgb(37, 138, 171);
  }
  
  .question-section-unique {
    margin-bottom: 2rem;
  }
  
  .question-count-unique {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: var(--primary-color);
    margin-top: 2rem;
  }
  
  .question-text-unique {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    margin-top: 2rem;
  }

  .answer-section-unique {
    border: 2px solid var(--border-color); /* Defines the border around the fieldset */
    padding: 1rem;
    border-radius: 8px; /* Rounds the corners */
    margin: 1rem auto;
    max-width: 600px; /* Restricts the width */
    font-size: 1.5rem;
  }
  
  .quiz-option-unique {
    margin: 1rem 0; /* Adds space between options */
  }
  
  
  .answer-section-unique button {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    background-color: rgb(201, 3, 3);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .answer-section-unique button:hover {
    background-color: rgb(218, 70, 70);
  }
  
  .feedback-unique {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
  
  .feedback-unique.correct {
    color: rgb(0, 82, 0);
  }
  
  .feedback-unique.incorrect {
    color: rgb(169, 1, 1);
  }
  
  .score-section-unique {
    margin-top: 2rem;
  }
  
  .score-feedback-unique {
    background-color: white;
    color: black;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .score-section-unique p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .next-button-unique {
    padding: 0.5rem 1.5rem;
    background-color: rgb(201, 3, 3);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .next-button-unique:hover {
    background-color: rgb(218, 70, 70)
  }

  .falling-star {
    position: absolute;
    top: 0;
    left: 50%;
    width: 80px; /* ⬆ Increased size */
    height: 80px;
    background-color: rgb(203, 203, 3); /* Star color */
    clip-path: polygon(
      50% 0%, 61% 35%, 98% 35%, 
      68% 57%, 79% 91%, 50% 70%, 
      21% 91%, 32% 57%, 2% 35%, 
      39% 35%
    ); /* Keeps star shape */
    animation: fall 2s linear forwards; /* Ensures it disappears */
  }
  
  @keyframes fall {
    0% {
      transform: translateY(0) rotate(0deg) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh) rotate(180deg) scale(1.5); /* ⬆ Slight scale effect */
      opacity: 0;
    }
  }
  