:root {
  --primary-color: #005f73;
  --light-background: #f4f4f9;
  --pink-color: rgb(251, 206, 201);
  --yellow-color: #f9ca63;
  --dark-teal: #006d77;
  --white-color: #f0f8ff;
}

.cybersecurity-basics-container {
    padding: 2rem;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin: 2rem auto;
    max-width: 1000px;
    text-align: left;
    position: relative;
   
    
    
  }
  


/* Custom Play/Pause Button */
.custom-controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.custom-controls button {
  background-color: black;
  color: white;
  border: none;
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  transition: none;
}

.custom-controls button:focus {
  outline: none;
}

/* Other necessary styles */
.cybersecurity-basics-container {
  padding: 2rem;
  background-color: var(--light-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 1000px;
  text-align: left;
  position: relative;
}

.BasicsTitle-smaller {
  font-size:2.5rem !important;
  color: var(--primary-color); /* Change this to your preferred color */
  text-align: center; /* Aligns the title to the center */
  margin-bottom: 1rem;
}

.bordered-container {
  border: 2px solid black;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: white;
  color: black
}

.bordered-container p {

  font-size: 1.3rem !important;
  color: black !important;
  margin-bottom: 1rem; /* Remove bottom margin */
}

/* Other button and container styles for navigation, etc. */

  
  .module-section {
    margin-bottom: 1rem;
  }

  .module-section2 {
    margin-bottom: 1rem;
  }

  .module-section3 {
    margin-bottom: 1rem;
  }
  
  .module-section h2 {
    font-size: 1.5rem;
    color: black;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }

  .module-section h3 {
    font-size: 1.5rem;
    color: black;
    margin-bottom: 1rem;
    margin-top: 1.1rem;
  }

  .module-section3 h2 {
    font-size: 1.5rem !important;
    color: black;
    margin-bottom: 1rem;
    
  }
  
  .module-content {
    font-size: 1.25rem; /* Ensure this matches with RecognizingThreatsPartTwo */
    color: black;
    margin-bottom: 1rem;
  }

  .module-content2 {
    font-size: 1.25rem; /* Ensure this matches with RecognizingThreatsPartTwo */
    color: black;
    margin-bottom: 1rem;
  }
  
  .module-content3 {
    font-size: 1.25rem; /* Ensure this matches with RecognizingThreatsPartTwo */
    color: black;
    margin-bottom: 1rem;
  }

  .thumbnail {
    max-width: 300px;
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .bordered-container {
    border: 2px solid black;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    background-color: var(--primary-color);
    color: var(--text-color);
  }

  .bordered-container2 {
    border: 2px solid black;
    padding: 0rem;
    border-radius: 8px;
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
    background-color: var(--primary-color);
    color: var(--text-color);
  }

  .bordered-container3 {
    border: 2px solid black;
    padding: 0rem;
    border-radius: 8px;
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
    background-color: white;
    color: black;
    font-weight: 400;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .grid-container2 {
    display: grid;
    gap: 0rem !important;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .grid-item.image {
    align-items: center;
  }
  
  .grid-item.text {
    align-items: flex-start;
    margin-bottom: 0rem;
    margin-left: 1rem;
    margin-right: 0.4rem;
  }

  .grid-item.text2 {
    align-items: flex-start;
    margin-bottom: 0rem;
    margin-left: 1rem;
    margin-right: 0.4rem;
    
  }

  .grid-item.text3 {
    align-items: flex-start;
    margin-bottom: 0rem;
    margin-left: 1rem;
    margin-right: 0.4rem;
    
  }

  
  .message h5 {
    font-size: 1.5rem;
    margin-bottom: 2rem !important;
  }
  
  .start-quiz-button {
    display: block;
    width: 100%;
    padding: 1rem;
    background-color: rgb(201, 3, 3);
    color: white !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-size: 1.25rem;
    transition: background-color 0.3s ease;
    margin-bottom: 1rem;
  }
  
  .start-quiz-button:hover {
    background-color: rgb(218, 70, 70);
  }
  
  .quiz-section {
    margin-top: 1rem;
    background-color: var(--light-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .quiz-section h4 {
    font-size: 1.25rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
  }
  
  .quiz-section button {
    display: block;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: rgb(201, 3, 3);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .quiz-section button:hover {
    background-color: rgb(218, 70, 70);
  }
  
  .correct {
    color: green;
    font-weight: bold;
  }
  
  .incorrect {
    color: rgb(209, 4, 4);
    font-weight: bold;
  }
  
  .next-quiz-button {
    display: block;
    width: 100%;
    padding: 1rem;
    background-color: rgb(201, 3, 3);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-size: 1.25rem;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
  }

  .KeyPoints {
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;
}

.KeyPoints li {
    display: flex;
    align-items: flex-start; /* Aligns number-circle to the top of the text */
    font-size: 18px;
    margin-bottom: 2rem;
    gap: 10px; /* Adds spacing between the circle and text */
}

.number-circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    background-color: rgb(201, 3, 3);
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; /* Prevents resizing */
}

  
  
  .next-quiz-button:hover {
    background-color: rgb(218, 70, 70);
  }
  
  .back-button-container {
    display: flex;
    justify-content: center;
    margin-top: 4.4rem !important;
  }
  
  .back-button {
    padding: 0.5rem 1.5rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }


  
  .back-button:hover {
    background-color: rgb(37, 138, 171) !important;
  }
  
  .hide-content .module-section,
  .hide-content .back-button-container,
  .hide-content .audio-controls {
    display: none;
  }
  
  .next-button:hover {
    background-color: rgb(218, 70, 70);
  }

  .salmon-text {
    color: salmon;
    font-weight: bold;
}
.next-button {
  color: rgb(201, 3, 3) !important;
}

.grid-item-text2 p {
  color: black !important;
}

.grid-container3 {
  margin-bottom:  20px;
}