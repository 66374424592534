/* Settings.css */
:root {
    --primary-color: #005f73;
   
  }

.settings-container-manager {
    
        padding: 40px;
        background: white;
        border-radius: 10px;
        max-width: 850px;
        margin: 0 auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 2px solid var(--border-color);
    
    
}

.settings-title {
    font-size: 2.7rem !important;
    font-weight: bold;
    text-align: center;
    color:black !important;
    margin-bottom: 40px !important;
    font-weight: 500;
}

.settings-section {
    margin-bottom: 30px;
}

.settings-subheading22 {
    margin-bottom: 15px;
    font-size: 2rem;
    color: salmon;
}

.settings-subheading2 {
    color: salmon;
    margin-bottom: 15px;
    font-size: 2rem;
}

.settings-text {
    margin-bottom: 20px;
    color: salmon !important;
    font-weight: 900;
    font-size: 2rem;
   
}

.settings-text2 {
    margin-bottom: 20px;
    color: salmon;
    font-weight: 900;
    font-size: 1.5rem;
   
}

.settings-text3 {
    margin-bottom: 30px;
    color: salmon;
    font-weight: 1200;
   
}

.settings-danger-button {
    background-color: black;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.settings-danger-button2 {
    background-color: #000000 !important;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 1.5rem;
}

.settings-danger-button2:hover {
    background-color: #3e3e3e;
}

.settings-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.settings-employee-email {
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: #000000;
}

.settings-employee-name {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #000000;
}

.settings-warning-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.settings-warning-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.settings-warning-buttons {
    display: flex;
    justify-content: space-between;
}

.settings-cancel-button {
    background-color: #555;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.settings-cancel-button:hover {
    background-color: #444;
}

.settings-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns for larger screens */
    gap: 20px;
}

@media (max-width: 768px) {
    .settings-grid {
        grid-template-columns: 1fr; /* One column on smaller screens */
    }
}
