.admin-dashboard {
  text-align: center;
  padding: 20px;
}

/* Statistics Section */
.dashboard-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

.stat-card {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  text-align: center;
  margin: 10px;
}

.stat-card h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.stat-card p {
  font-size: 1.5em;
  font-weight: bold;
  color: #007bff;
}

/* Recent Logins Section */
.recent-logins {
  width: 100%;
  margin-top: 20px;
}

.recent-logins h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.recent-logins ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0 auto;
  width: 60%;
}

.recent-logins li {
  background-color: #f1f1f1;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.recent-logins li span {
  font-weight: bold;
}

/* Portal Boxes Section */
.portal-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.portal-box {
  width: 250px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.portal-box h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.portal-box p {
  font-size: 14px;
  margin-bottom: 20px;
  color: #666;
}

.portal-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.portal-button:hover {
  background-color: #0056b3;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .stat-card {
    width: 150px;
  }

  .recent-logins ul {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .stat-card {
    width: 100%;
  }

  .portal-box {
    width: 100%;
  }

  .recent-logins ul {
    width: 100%;
  }
}
