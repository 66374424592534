/* Reset Password Page Styles */
.reset-password-page-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 150px;
  }
  
  .reset-password-page-container h1 {
    font-size: 1.8rem;
    color: #333;
  }
  
  .reset-password-page-message {
    font-size: 1rem;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  .reset-password-page-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .reset-password-page-input-group {
    text-align: left;
  }
  
  .reset-password-page-input-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .reset-password-page-input-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .reset-password-page-helper-text {
    font-size: 0.9rem;
    color: #6c757d;
  }
  
  .reset-password-page-button {
    background-color: black;
    color: white;
    border: none;
    padding: 12px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .reset-password-page-button:hover {
    background-color: rgb(67, 66, 66);
  }
  
  .reset-password-page-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .reset-password-page-back-button {
    background-color: transparent;
    color: black;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .reset-password-page-back-button:hover {
    text-decoration: underline;
  }
  