/* Container and basic layout */
.sorting-game-container {
  padding: 0rem;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 1rem auto;
  max-width: 1250px;
  text-align: center;
  position: relative; /* Added for positioning */

}

.terms-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;  /* You can also reduce the bottom margin */
  /* min-height: 150px;  Remove or comment out this line */
  /* This allows the container to shrink as items disappear */
}

/* Dark overlay behind the modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Ensure the modal is on top */
}

/* The modal box itself */
.modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%; /* Responsive for small screens */
}

.modal-content h2 {
  margin-bottom: 1rem;
}

.modal-content p {
  margin-bottom: 2rem;
}


.term.dragging {
  opacity: 0.7;
  transform: scale(1.05);
  border: 2px dashed rgb(95, 93, 93);
  background-color: #f0f0f0;
}

.term {
  display: inline-block;
  width: 220px;          /* Fixed width */
 height: 65px;
  background-color: black;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  margin: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;    /* Centers the text horizontally */
}


.categories-container {
  display: flex;
  justify-content: space-around;
}

.category {
  background-color: var(--secondary-color);
  padding: 1rem;
  border-radius: 4px;
  width: 45%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed var(--primary-color);
}

.category h3 {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.game-complete {
  margin-top: 2rem;
}

.game-complete p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

button {
  padding: 0.5rem 1rem;
  background-color: rgb(201, 3, 3);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem;
}



/* Back arrow button styling at the top left */
.back-arrow-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: black;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease;
}

.back-arrow-button:hover {
  color: #005f73;
}

.correct {
  color: rgb(0, 89, 0);
}

.incorrect {
  color: rgb(201, 3, 3);
}

.good-term {
  color: rgb(255, 255, 255);
}

.bad-term {
  color: white;
}

.next-button {
  margin-top: 1rem;
}
.answer-buttons2 {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  color: #ddd !important;
}

.gametext {
  text-align: left;
  color: rgb(69, 69, 69);
}