



/* General Button Styles */
.start-quiz-button, 
.next-quiz-button, 
.quiz-section button, 
.retake-button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: rgb(247, 5, 5);
  color: white !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-bottom: 0.75rem;
  margin-top: 0.1rem;
}



.start-quiz-button:hover, 
.next-quiz-button:hover, 
.quiz-section button:hover, 
.retake-button:hover {
  background-color: rgb(255, 68, 68);
}


/* Styling for the back arrow button */
.back-arrow-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border: none;
  font-size: 40px; /* Larger size */
  color: #333;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease;
  z-index: 1000; /* Ensure the button stays on top of other content */
  
}

.back-arrow-button:hover {
  color: #005f73; /* Change color on hover */
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .back-arrow-button {
    font-size: 20px; /* Reduce arrow size for smaller screens */
    top: 10px;
    left: 10px;
  }

  .BasicsTitle {
    margin-left: 70px; /* Adjust title margin for smaller screens */
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .back-arrow-button {
    font-size: 30px; /* Further reduce arrow size */
    top: 5px;
    left: 5px;
  }
}

/* Submit Button Styles */
.submit-button {
  display: block !important;
  width: 100% !important;
  padding: 0.75rem !important;
  background-color: rgb(201, 3, 3) !important;
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  text-align: center !important;
  font-size: 1rem !important;
  transition: background-color 0.3s ease !important;
  margin-bottom: 0.75rem !important;
  margin-top: 0.1rem !important;
}

.submit-button:hover {
  background-color: rgb(218, 70, 70) !important;
}

/* Back Button Styles */
.back-button-container {
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
}

.back-button {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: var(--hover-color);
}





/* Other necessary styles as per your requirements */



.introduction-container2 {
  padding: 2rem;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 1000px;
  text-align: left;
  position: relative;
  margin-left: -50px;
}

.BasicsTitle {
  font-size:3rem !important;
  color: black; /* Change this to your preferred color */
  text-align: center; /* Aligns the title to the center */
  margin-bottom: 1rem;
  margin-top: -30px;
  margin-left: 0px;
  padding: 20px;
  font-weight: 500;
}


.cybersecurity-basics-header {
  text-align: center;
  margin-bottom: 0rem;
}

.cybersecurity-basics-header h2 {
  font-size: 2rem !important;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  margin-top: 0rem;
}

.bordered-content {
  border: 0px solid black;
  padding: 0.75rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background-color: white; /* Add primary color as background */
}

.module-section {
  margin-bottom: 1rem;
}

.module-content h3 {
  font-size: 1.25rem;
  color: var(--primary-color);
}

.module-content p {
  font-size: 1.3rem !important;
  color: white !important;
  margin-bottom: 0; /* Remove bottom margin */
}

.introduction-image {
  width: 40%;
  height: auto;
  margin: 0 0 0 1rem; /* Adjust margins */
  display: block; 
  border-radius: 8px;
}

.quiz-image {
  width: 40%;
  height: auto;
  display: block; 
  border-radius: 8px;
  margin-right: 1rem;
  margin-bottom: 0.1rem;
}

.content-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.content-layout .module-content {
  flex: 1;
  margin-right: 1rem;
}

.content-with-image {
  display: flex;
  align-items: center;
}

.bordered-paragraph {
  border: 2px solid black;
  padding: 0.75rem;
  border-radius: 8px;
  margin-bottom: 0; /* Ensure no margin at the bottom */
  text-align: left; /* Align the paragraph text to the left */
  flex: 1;
}

.highlighted-section {
  border: 2px solid black;
  padding: 0.75rem;
  border-radius: 8px;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  background-color: white; /* Add primary color as background */

}


.message {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
}

.quiz-message {
  flex: 1;
  
  
}

.message h3 {
  font-size: 1.25rem;
  margin: 0; /* Remove default margin */
  
}

.flip-card-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.CardFlip {
  width: 400px;
  height: 300px;
  perspective: 1000px;
  cursor: pointer;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.CardFlip.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  padding: 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Center text in front */
.card-front {
  background-color: #f8f9fa;
  color: #000;
  border: 2px solid rgb(201, 3, 3);
  display: flex;
  align-items: center;  /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  text-align: center;
}

/* Left-align text in back */
.card-back {
  background-color: #ffffff;
  color: #000;
  border: 2px solid rgb(201, 3, 3);
  transform: rotateY(180deg);
  display: flex;
  align-items: center;  /* Vertical centering */
  justify-content: flex-start; /* Align text to the left */
  text-align: left;
}



.quiz-section {
  margin-top: 0.75rem;
  background-color: var(--light-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  position: relative;
}

.message {
  border: 2px solid black;
  padding: 0.75rem;
  border-radius: 8px;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  
}

.quiz-section h3 {
  font-size: 1rem;
  color: var(--primary-color) !important;
  margin-bottom: 0.5rem;
}

.correct, .incorrect {
  font-weight: bold;
}

.correct {
  color: green;
}

.incorrect {
  color: rgb(171, 1, 1);
}

.quiz-back-button-container {
  display: flex;
  justify-content: flex-start; /* Align the button to the left */
  margin-bottom: 1rem; /* Space between button and the quiz content */
}

.quiz-back-button {
  padding: 0.5rem 1.5rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.quiz-back-button:hover {
  background-color: var(--hover-color);
}

.hide-next-button .next-button {
  display: none; 
}

.hide {
  display: none;
}

.falling-star {
  position: absolute;
  top: 0;
  left: 50%;
  width: 80px; /* ⬆ Increased size */
  height: 80px;
  background-color: rgb(203, 203, 3); /* Star color */
  clip-path: polygon(
    50% 0%, 61% 35%, 98% 35%, 
    68% 57%, 79% 91%, 50% 70%, 
    21% 91%, 32% 57%, 2% 35%, 
    39% 35%
  ); /* Keeps star shape */
  animation: fall 2s linear forwards; /* Ensures it disappears */
}

@keyframes fall {
  0% {
    transform: translateY(0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(180deg) scale(1.5); /* ⬆ Slight scale effect */
    opacity: 0;
  }
}


.start-button-home {
  
  background-color: rgb(201, 3, 3) !important;
  margin-left: 320px;
}

.quiz-fieldset {
  border: none; /* Remove default border */
  margin: 0;
  padding: 0;
}

.quiz-legend {
  font-weight: bold;
  margin-bottom: 1rem;
}


.quiz-fieldset legend {
  font-size: 1.2rem; /* Adjust font size */
  font-weight: bold;
  margin-bottom: 1rem;
}

.popup {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 1rem;
  border: 2px solid black;
  border-radius: 8px;
  z-index: 2000;
  max-width: 80%;
  text-align: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.phishing-popup {
  top: 20%;
}

.impersonation-popup {
  top: 40%;
}


