:root {
  --primary-color: #005f73;
  --light-background: #f4f4f9;
  --pink-color: rgb(251, 206, 201);
  --yellow-color: #f9ca63;
  --dark-teal: #006d77;
  --white-color: #f0f8ff;
}

/* Apply background color to body and html to extend beyond content */
.legal-documents-body {
  height: auto;
  margin: 0;
  padding: 0;
  background: 
  url('https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Fcubes.png?alt=media&token=72d20a6d-223f-4357-adf0-a93ab480ac85'), /* Local path to cubes.png */
  linear-gradient(90deg, aliceblue, aliceblue); /* Gradual two-tone background */
}

/* General container styling for both the privacy policy and user agreement pages */
.legal-privacy-policy-container,
.legal-user-agreement-container {
  max-width: 800px;
  margin: 0 auto; /* This ensures the container is centered */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  line-height: 1.6;
  min-height: 100vh; /* Ensure container takes full height of viewport */
  position: relative; /* Ensure proper positioning */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* Ensure the content stays centered */
}

.legal-h1 {
  font-size: 2em;
  color: #2c3e50;
  text-align: left;
  margin-bottom: 20px;
}

.legal-h2 {
  font-size: 1.5em;
  color: #34495e;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.legal-p {
  font-size: 1em;
  color: #555;
  margin-bottom: 15px;
  text-align: left;
}

.legal-ul {
  padding-left: 20px;
  margin-bottom: 15px;
  text-align: left;
}

.legal-ul li {
  list-style-type: disc;
  margin-bottom: 8px;
}

.legal-a {
  color: #1abc9c;
  text-decoration: none;
}

.legal-a:hover {
  text-decoration: underline;
}

/* Add some spacing between sections */
.legal-privacy-policy-container .legal-p,
.legal-user-agreement-container .legal-p,
.legal-privacy-policy-container .legal-ul,
.legal-user-agreement-container .legal-ul {
  margin-bottom: 20px;
}

/* Styling the divider for each section */
.legal-hr {
  border: none;
  height: 1px;
  background-color: #e1e1e1;
  margin: 30px 0;
}

/* Adjusting the font for better readability */
.legal-privacy-policy-container .legal-p,
.legal-user-agreement-container .legal-p {
  font-size: 1.1em;
}

/* Footer Links (if you use them for navigation purposes) */
.legal-footer-link {
  font-size: 0.9em;
  color: #1abc9c;
  text-decoration: underline;
  cursor: pointer;
}

.legal-footer-link:hover {
  color: #16a085;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .legal-privacy-policy-container,
  .legal-user-agreement-container {
      padding: 15px;
  }

  .legal-h1 {
      font-size: 1.8em;
  }

  .legal-h2 {
      font-size: 1.3em;
  }

  .legal-p,
  .legal-ul {
      font-size: 1em;
  }
}

@media (max-width: 480px) {
  .legal-privacy-policy-container,
  .legal-user-agreement-container {
      padding: 10px;
      font-size: 0.9em;
  }

  .legal-h1 {
      font-size: 1.6em;
  }

  .legal-h2 {
      font-size: 1.3em;
  }

  .legal-p,
  .legal-ul {
      font-size: 0.85em;
  }

  .legal-ul {
      padding-left: 15px; /* Adjust padding for small screens */
  }
}
