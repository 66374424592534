.add-report-body {
  height: auto;
  margin: 0; /* Optional: Reset margins if necessary */
  padding: 20px; /* Optional: Add padding for spacing */
}


.add-report-container {
  height: auto; /* Ensure container height adjusts based on content */
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background: white; 
  border: 2px solid var(--border-color);
background-position: center;

  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
  
.report-label {
  color: black;
}

.add-report-container h1 {
    font-size: 2rem;
    margin-bottom: 0px !important;
    color: black;
    text-align: center;
    margin-bottom: 2rem !important;
    font-weight: 500;
  }
  
  .add-report-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  input[type="text"]:focus,
  textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .error-text {
    color: #e74c3c;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
  }
  
  .success-text {
    color: #2ecc71;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
  }
  
  .submit-button22 {
    padding: 12px 20px;
    font-size: 1rem;
    color: white;
    background-color: rgb(201, 3, 3);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    font-weight: bolder;
  }
  
  .submit-button:hover {
    background-color: rgb(218, 70, 70);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .add-report-container {
      padding: 15px;
      margin: 30px auto;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    .submit-button {
      font-size: 0.9rem;
      padding: 10px 15px;
    }
  }
  /* Additional CSS for the Dropdown Menu */
select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    background-color: #fff;
  }
  
  select:focus {
    border-color: #007bff;
    outline: none;
  }
  .warning-text2 {
    color: rgb(201, 3, 3);
    font-weight: bold;
    margin-top: 5px;
  }
  