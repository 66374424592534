.drop-drag-game-container {
  padding: 2rem;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 1200px;
  text-align: center;
  position: relative; /* Added for positioning */
}

/* Back arrow button styling at the top left */
.back-arrow-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: black;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease;
}
 .GameTitle {
  color: black;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 500;
 }

.back-arrow-button:hover {
  color: #005f73;
}



/* Highlight a selected term */
.term.selected {
  border: 2px solid var(--highlight-color, #FFD700);
  background-color: var(--primary-color-dark, #313131);
}

/* Highlight category containers when a term is selected */
.category.highlight {
  border-color: var(--highlight-color, #FFD700);
  box-shadow: 0 0 10px var(--highlight-color, #FFD700);
}

.gametext {
  text-align: left;
  color: rgb(69, 69, 69);
  margin-bottom: 2rem;
}



.categories-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
}

.category {
  flex: 1;
  min-width: 200px;
  padding: 1rem;
  border: 2px dashed var(--border-color);
  border-radius: 8px;
  background-color: var(--light-background);
  min-height: 150px;
}

.category h3 {
  margin-bottom: 1rem;
}

.game-complete {
  margin-top: 2rem;
}

.game-complete button {
  padding: 0.75rem 1.5rem;
  background-color: rgb(201, 3, 3);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.game-complete button:hover {
  background-color: rgb(218, 70, 70);
}
