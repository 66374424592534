.audio-controls {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 0rem; /* Adjusted for better spacing */
  }
  
  .audio-controls button {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }

  .salmon-text {

    font-size: 1.5rem;
  }
  
  .audio-controls button:hover {
    background-color: var(--hover-color);
  }
  
  .cybersecurity-basics-container {
    padding: 1rem;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin: 1rem auto;
    max-width: 1000px;
    text-align: left;
    position: relative;
  }
  
  .cybersecurity-basics-header {
    text-align: center;
    margin-bottom: 0rem;
  }
  
  .cybersecurity-basics-header h2 {
    font-size: 3rem !important;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    margin-top: 0rem;
  }
  
  .bordered-container {
    border: 0px solid black;
   
    margin-bottom: 1rem;
    background-color: white; /* Add primary color as background */
  }
  
  .bordered-container.alt-color {
    background-color: white;
  }
  
  .bordered-container.alt-color h3 {
    color: black;
  }
  
  .module-section {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  
  .module-content h3 {
    font-size: 1.25rem;
    color: black;
  }
  
  .module-content p {
    font-size: 1.3rem !important;
    color: black !important;
    margin-bottom: 0; /* Remove bottom margin */
  }
  
  .bordered-container.alt-color p {
    color: black !important;
  }
  
  .introduction-image {
    width: 40%;
    height: auto;
    margin: 0 0 0 1rem; /* Adjust margins */
    display: block; 
    border-radius: 8px;
  }
  
  .quiz-image {
    width: 40%;
    height: auto;
    display: block; 
    border-radius: 8px;
    margin-right: 1rem;
    margin-bottom: 0.1rem;
  }
  
  .content-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .content-layout .module-content {
    flex: 1;
    margin-right: 1rem;
  }
  
  .content-with-image {
    display: flex;
    align-items: center;
  }
  
  .bordered-paragraph {
   
    margin-bottom: 0; /* Ensure no margin at the bottom */
    text-align: left; /* Align the paragraph text to the left */
    flex: 1;
  }
  
  .highlighted-section {
    border: 0px solid black;
    padding: 0.75rem;
    border-radius: 8px;
    margin-top: 0.75rem;
    margin-bottom: 0rem;
    background-color: white; /* Add primary color as background */
  }
 
  .highlighted-section2 {                   /* Alternative highlight section with blac border */
    
    background-color: white; 
  }
  
  .message {
    display: flex;
    align-items: center;
  }
  
  .quiz-message {
    flex: 1;
  }
  
  .message h5 {
    font-size: 1.25rem;
    margin: 0; /* Remove default margin */
  }
  
  .start-quiz-button, .next-quiz-button, .quiz-section button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color: rgb(201, 3, 3);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-bottom: 0.75rem;
    margin-top: 0.1rem;
  }
  
  .start-quiz-button:hover, .next-quiz-button:hover, .quiz-section button:hover {
    background-color: rgb(218, 70, 70);
  }
  
  .quiz-section {
    margin-top: 0.75rem;
    background-color: var(--light-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    position: relative;
  }
  
  .message {
    border: 2px solid black;
    padding: 0.75rem;
    border-radius: 8px;
    margin-top: 0.75rem;
    margin-bottom: 2rem;
    /* Add primary color as background */
  }
  
  .quiz-section h4 {
    font-size: 1rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
  }
  
  .correct, .incorrect {
    font-weight: bold;
  }
  
  .correct {
    color: green;
  }
  
  .incorrect {
    color: rgb(201, 3, 3);
  }
  
  .back-button-container {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
  }
  
  .back-button {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .quiz-back-button-container {
    display: flex;
    justify-content: flex-start; /* Align the button to the left */
    margin-bottom: 1rem; /* Space between button and the quiz content */
  }
  
  .quiz-back-button {
    padding: 0.5rem 1.5rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover, .quiz-back-button:hover {
    background-color: rgb(218, 70, 70);
  }
  
  .hide-next-button .next-button {
    display: none; 
  }
  
  .hide {
    display: none;
  }
  
  .quiz-option-label {
    display: flex;
    align-items: flex-start;
}

.quiz-option-label input {
    margin-top: 4px; /* Adjust this value as needed */
}

.option-text {
    margin-left: 10px; /* Adjust this value to move the text further away from the radio button */
}

.link-text {
  color: black !important;
  text-decoration: underline;
  cursor: pointer;
}
