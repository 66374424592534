.manage-users-container {
  width: 98%;
  max-width: 1200px; /* Limit the width for large screens */
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f7f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  overflow-x: auto; /* Ensure horizontal scrolling on smaller screens */
}

.users-table th,
.users-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  word-wrap: break-word; /* Handle long content within table cells */
}

.users-table th {
  background-color: #0056b3;
  color: #fff;
}

.users-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.users-table tr:hover {
  background-color: #ddd;
}

.delete-user-button {
  background-color: transparent;
  color: red;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.delete-user-button:hover {
  color: #c9302c;
}

.no-results {
  text-align: center;
  font-size: 18px;
  color: #999;
}

.back-button-manageusers {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.back-button-manageusers:hover {
  background-color: #004095;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .users-table th, .users-table td {
    padding: 8px;
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .search-input {
    font-size: 14px;
    padding: 10px;
  }

  h1 {
    font-size: 24px;
  }

  .back-button-manageusers {
    padding: 8px 12px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .users-table th, .users-table td {
    display: block;
    width: 100%; /* Stack table rows on small screens */
    text-align: right;
    padding: 8px;
    font-size: 12px;
    border: none;
  }

  .users-table th::before {
    content: attr(data-label); /* Add data-label attribute for responsive tables */
    float: left;
    font-weight: bold;
  }

  .users-table td::before {
    content: attr(data-label); /* Add data-label attribute for responsive tables */
    float: left;
    font-weight: bold;
  }

  .back-button-manageusers {
    padding: 6px 10px;
    font-size: 12px;
  }

  .search-input {
    padding: 8px;
    font-size: 12px;
  }
}
