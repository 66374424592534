/* Base styling for the container */
.FinishersPageOne-introduction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    min-height: 100vh;
    background: 
    url('https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Fcubes.png?alt=media&token=72d20a6d-223f-4357-adf0-a93ab480ac85'), /* Local path to cubes.png */
    linear-gradient(90deg, aliceblue, aliceblue); /* Gradual two-tone background */
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  /* Styling for the section */
  .FinishersPageOne-story-introduction {
    max-width: 800px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: center;
  }
  
  .FinishersPageOne-story-introduction h2 {
    font-size: 2rem;
    color: black;
    margin-bottom: 20px;
  }
  
  /* Text content inside the introduction */
  .FinishersPageOne-introduction-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  /* Text paragraph inside the introduction */
  .FinishersPageOne-introduction-text p {
    font-size: 1.2rem;
    color: black;
    margin-bottom: 30px;
    line-height: 1.6;
    text-align: left;
  }
  
  /* Button styling */
  .FinishersPageOne-start-button-home {
    background-color: black;
    color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .FinishersPageOne-start-button-home:hover {
    background-color: rgb(66, 66, 66);
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .FinishersPageOne-story-introduction {
      padding: 30px;
    }
  
    .FinishersPageOne-story-introduction h2 {
      font-size: 1.8rem;
    }
  
    .FinishersPageOne-introduction-text p {
      font-size: 1.1rem;
    }
  
    .FinishersPageOne-start-button-home {
      padding: 12px 24px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .FinishersPageOne-story-introduction {
      padding: 20px;
    }
  
    .FinishersPageOne-story-introduction h2 {
      font-size: 1.5rem;
    }
  
    .FinishersPageOne-introduction-text p {
      font-size: 1rem;
    }
  
    .FinishersPageOne-start-button-home {
      padding: 10px 20px;
      font-size: 0.85rem;
    }
  }
  