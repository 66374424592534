.grouped-users {
  padding: 20px;
  max-width: 1200px; /* Limit the width for larger screens */
  margin: 0 auto;
}

.grouped-users h1 {
  text-align: center;
  font-size: 28px;
  color: #333;
}

.search-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.grouped-users-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center elements */
}

.manager-box {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.manager-box h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.manager-box ul {
  list-style-type: none;
  padding-left: 0;
}

.manager-box li {
  margin: 5px 0;
}

.grouped-users p {
  text-align: center;
  font-size: 18px;
  color: #999;
}

.back-button-group {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.back-button-group:hover {
  background-color: #004095;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .grouped-users h1 {
    font-size: 24px;
  }

  .search-input {
    padding: 10px;
    font-size: 14px;
  }

  .manager-box {
    width: 100%; /* Make boxes full width on smaller screens */
  }

  .back-button-group {
    padding: 8px 12px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .grouped-users h1 {
    font-size: 20px;
  }

  .search-input {
    padding: 8px;
    font-size: 12px;
  }

  .manager-box {
    width: 100%; /* Full width for small screens */
  }

  .back-button-group {
    padding: 6px 10px;
    font-size: 12px;
  }
}
