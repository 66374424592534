.logo-overlay {
    position: absolute; /* Place it inside the video container */
    top: 10px; /* Distance from the top of the video */
    left: 20px !important; /* Distance from the left */
    z-index: 10; /* Ensure it's above the video */
  }
  
  .logo-img {
    width: 80px; /* Adjust size as per your needs */
    height: auto;
    opacity: 1; /* Optional: semi-transparency */
  }
  