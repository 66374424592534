:root {
  --sidebar-height: 93vh; /* Default height, can be easily changed */
  --hover-color: #0a9396; /* Color when hovering over a link */
  --white: #ffffff;
}

.sidebar2 {
  width: 180px;
  height: 75h !important; /* Sidebar height */
  background: white;
  color: var(--white);
  position: relative;
  top: 0rem; /* Adjust based on your top bar height */
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: 1rem;
  border-left: 1px solid grey; /* Thin grey line on the left */
  z-index: 1000; /* Ensure it is on top of other content */
  transition: transform 0.3s ease-in-out;
}

/* Sidebar Hidden for Small Screens */
.sidebar2-hidden {
  transform: translateX(-180px); /* Move it off-screen */
}

/* Sidebar Toggle Button */
.sidebar2-toggle-btn {
  position: absolute;
  bottom: 0px; /* Stick to the bottom of the sidebar */
  left: 0;
  background-color: #0a9396;
  color: white;
  padding: 10px;
  transform: rotate(-90deg); /* Rotate the text vertically */
  transform-origin: left top;
  cursor: pointer;
  font-size: 16px;
  z-index: 2000;
  display: none; /* Hidden by default for large screens */
}

@media (max-width: 768px) {
  /* For small screens, show the toggle button */
  .sidebar2-toggle-btn {
    display: block; /* Show button */
    padding: 1px;
  }

  .sidebar2 {
    position: fixed; /* Sidebar is fixed on small screens */
    top: 58px;
    left: 0;
    height: 110vh; /* Full viewport height */
    display: none; /* Hide sidebar initially */
  }

  .sidebar2.show {
    display: block; /* Slide the sidebar in when shown */
    transform: translateX(0);
  }
}

.sidebar2-ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.sidebar2-li {
  list-style-type: none; /* Remove bullet points on individual list items */
  margin: 1.5rem 0; /* Increase space between each list item */
}

.sidebar2-li:hover {
  color: var(--hover-color);
  cursor: pointer;
}

.sidebar2 a {
  color: black;
  text-decoration: none;
}

.sidebar2 a:hover {
  color: rgb(49, 48, 48);
}

/* Style for active link */
.sidebar2 a.active2 {
  color: black;
  font-weight: bolder;
  
  padding: 0rem; /* Optional padding for better visibility */
}
/* Custom Scroll Bar for Sidebar */
.sidebar2::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.sidebar2::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Rounded track corners */
}

.sidebar2::-webkit-scrollbar-thumb {
  background: #0a9396; /* Scroll thumb color */
  border-radius: 10px; /* Rounded scroll thumb */
}

.sidebar2::-webkit-scrollbar-thumb:hover {
  background: #005f73; /* Change color when hovered */
}

/* Firefox scrollbar styles */
.sidebar2 {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #0a9396 #f1f1f1; /* Scrollbar thumb and track colors */
}