/* General Sidebar Styles for large screens */
.sidebar {
  width: 190px; /* Sidebar width */
  height: 165vh !important; /* Sidebar height */
  background: white;
  color: black;
  position: relative; /* Normal position for large screens */
  top: 0rem;
  left: 0;
  overflow-y: auto;
  padding: 1rem;
  border-left: 1px solid grey; /* Thin grey line on the left */
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

/* Sidebar Toggle Button */
.sidebar-toggle-btn {
  position: absolute; /* Position the button inside the sidebar */
  bottom: 0px; /* Stick to the bottom of the sidebar */
  left: 0;
 
  
  background-color: #0a9396;
  color: white;
  padding: 10px;
  transform: rotate(-90deg); /* Rotate the text vertically */
  transform-origin: left top;
  cursor: pointer;
  font-size: 16px;
  z-index: 2000;
  display: none; /* Hidden by default for large screens */
}

@media (max-width: 768px) {
  /* For small screens, show the toggle button */
  .sidebar-toggle-btn {
    display: block; /* Show button */
    padding: 1px;
  }

  .sidebar {
    position: fixed; /* Sidebar is fixed on small screens */
    top: 58px;
    left: 0;
    height: 100vh; /* Full viewport height */
    display: none; /* Hide sidebar initially */
  }

  .sidebar.show {
    display: block; /* Slide the sidebar in when shown */
    transform: translateX(0);
  }
}

.sidebar ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin: 1.5rem 0;
}

.sidebar li:hover {
  color: #0a9396;
  cursor: pointer;
}

.sidebar a {
  color: black;
  text-decoration: none;
}

.sidebar a:hover {
  color: rgb(51, 51, 51);
}

/* Active link styling */
.sidebar a.active {
  
  font-weight: bolder;
  
  padding: 0;
}
/* Custom Scroll Bar for Sidebar */
.sidebar::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Rounded track corners */
}

.sidebar::-webkit-scrollbar-thumb {
  background: #0a9396; /* Scroll thumb color */
  border-radius: 10px; /* Rounded scroll thumb */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #005f73; /* Change color when hovered */
}

/* Firefox scrollbar styles */
.sidebar {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #0a9396 #f1f1f1; /* Scrollbar thumb and track colors */
}
