.threat-reports-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: black;
    border: 2px solid var(--border-color);
  
  }

  .threat-reports-container h1 {
    color: black;
    font-weight: 500;
  }
  
  .threat-reports-container {
    text-align: left;
  }
  
  .threat-reports-list {
    list-style-type: none;
    padding: 0;
  }
  
  .threat-report-item {
    background-color: black;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .threat-report-item h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
    font-weight: 500;
  }
  
  .threat-report-description {
    margin-top: 10px;
    font-size: 1em;
    color: #000000;
  }
  
  .threat-report-email,
  .threat-report-timestamp {
    margin-top: 10px;
    font-size: 0.9em;
    color: #000000;
  }
  .threat-report-item {
    position: relative;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .delete-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: none;
    border: none;
    color: rgb(110, 0, 0);
    cursor: pointer;
  }
  
  .delete-button:hover {
    color: rgb(44, 1, 1);
  }
  .threat-report-type {
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  