/* General reset for margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

}

body {
  background-color: white; /* Your background color */
}

/* Main container styling to avoid margins */


/* Video and section styling */
.section {
  width: 100%;
  margin: 0;
  padding: 0;
}

  /* Remove any solid background from the first section */
  .section-1-about {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto;
  flex-wrap: wrap; /* Enables wrapping on smaller screens */
  text-align: left; /* Ensure centering on smaller screens */
  }

  .section-title222-about22 {
    font-size: 3rem;
    color: #d32f2f;
    margin-bottom: 2rem;
    text-align: left;
    padding-top: 0px; /* Adds padding at the top to move the title down */
    margin-top: 2rem;
    max-width: 900px;
    margin-left: 100px;
    font-weight: 500;
  }

.toggle-subtitles-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #005f73;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-subtitles-button:hover {
  background-color: #003f50;
}

.image-container-training {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2rem; /* Adjust spacing as needed */
}

.training-image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 7.3rem;
  width: 500px;
  margin-right: 7.8rem; 
}



.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.section-1-home2 {
  margin: 0;
  padding: 0;
  background: white;
}

.section-2-home2part1 {
  margin: 0;
  padding: 0;
  background:#ffeadf;

}



.intro-video-wrapper-home2 {
  position: relative;
  display: inline-block;
  margin-top: 1rem;
  background: #000000;
 
}

.intro-video-home2 {
  width: 100%; /* Ensure it takes the full width */
  max-width: 1000px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  opacity: 1;
}

/* Second Section: Courses */
.section-2-home2 {
  background-color: #005f73;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content-wrapper-home2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Enables wrapping on smaller screens */
  text-align: center; /* Ensure centering on smaller screens */
}

.content-wrapper-home2new {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.content-wrapper-home2part1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Enables wrapping on smaller screens */
  text-align: center; /* Ensure centering on smaller screens */
}

.content-container-home2 {
  flex: 1;
  padding-right: 2rem;
}

.content-container-home2new {
  flex: 1;
  padding-right: 2rem;
}

.content-container-home2part1 {
  flex: 1;
  padding-right: 2rem;
}


.image-container-home2 {
  flex: 1;
  display: flex;
  justify-content: center;
}

.tutorial-image-course2 {
  max-width: 70%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  margin-left: 0px;
}

.section-title222-home2 {
  font-size: 3rem;
  color: white;
  margin-bottom: 1rem;
  text-align: center; /* Align to center for consistency */
  margin-top: 20px;
}

.section-title222-home2new {
  font-size: 1.6rem;
  font-weight: bold;
  max-width: 500px;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  margin-left: 100px;
  margin-top: 2rem;
  color: black;

}

.section-title222-home2new2 {
  font-size: 1.6rem;
  font-weight:500;
  max-width: 600px;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  margin-left: 100px;
  margin-top: 2rem;
  color: black;

}



.section-title222-home2part1 {
  font-size: 3rem;
  color: white;
  margin-bottom: 1rem;
  text-align: center; /* Align to center for consistency */
  margin-top: 20px;
}

.section-2-home2new {
  position: relative;
  padding: 4rem 2rem;
  text-align: center;
  color: black;
  overflow: hidden;
}

.section-2-home2new::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(to bottom, 
      rgba(255, 255, 255, 1) 0%, 
      rgba(255, 255, 255, 0.9) 8%, 
      rgba(255, 255, 255, 0.6) 15%, 
      rgba(255, 255, 255, 0.2) 30%, 
      rgba(255, 255, 255, 0) 50%, 
      rgba(255, 255, 255, 0.2) 70%, 
      rgba(255, 255, 255, 0.6) 85%, 
      rgba(255, 255, 255, 0.9) 92%, 
      rgba(255, 255, 255, 1) 100%),
    url('../assets/background121.jpg') no-repeat center center;
  background-size: cover;
  opacity: 0.5;
  z-index: 0;
}


.section-2-home2new > * {
  position: relative;
  z-index: 1;
}





.section-subtitle-home2 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 2rem;
  text-align: center;
  z-index: 1;
}

.section-subtitle-home2new {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 3rem;
  max-width: 550px;
  margin-left: 100px;
  
  
}

.section-subtitle-home2newmanagers {
  font-size: 1.2rem;
  color: #484848;
  margin-bottom: 3rem;
  max-width: 550px;
  
  
  
}

.courses-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 1.5rem;
  justify-content: center;
  align-items: start;
  max-width: 900px; /* Prevents excessive stretching */
  margin: 0 auto; /* Centers the grid */
}

/* Ensure the last course and the testimonial align properly */
.course-card:nth-child(3) {
  grid-column: 1;
}

.testimonial-card {
  grid-column: 2;
  align-self: center;
  
  padding: 1.5rem;
 
  text-align: center;
  font-style: italic;
  max-width: 400px;
}

.course-button-course-card {
  display: inline-block;
  padding: 5px 10px;
  font-size: 0.7rem;
  font-weight: bold;
  color: white;
  background-color:  #d32f2f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background 0.3s ease-in-out;
  
}

.course-button-course-card:hover {
  background-color: #003f50;
}


.testimonial-text {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.75rem;
}

.testimonial-author {
  font-weight: bold;
  color: #d32f2f;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .courses-grid {
    grid-template-columns: 1fr; /* Stack all items on smaller screens */
  }

  .testimonial-card {
    grid-column: auto; /* Reset the layout for small screens */
    justify-self: center;
  }
}


.course-card {
 background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 460px;
  text-align: left; /* Align text to the left for better readability */
  transition: transform 0.3s ease-in-out;
  height: auto; /* Allow height to adjust based on content */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 320px;
}

.course-list {
  list-style-type: disc;
  padding-left: 1.2rem; /* Ensure proper indentation */
  margin-top: 0.5rem;
  font-size: 0.95rem;
  color: "#555";
  line-height: 1.6;
}

.course-list li {
  margin-bottom: 0.5rem;
  color: rgb(109, 109, 109);
}

/* Ensure it maintains proper spacing and responsiveness */
@media (max-width: 768px) {
  .course-card {
    width: 100%;
    max-width: 300px;
    text-align: left;
  }

  .course-list {
    padding-left: 1rem;
    font-size: 0.9rem;
  }
}

.course-card-link {
  text-decoration: none;
  color: inherit;
}


.course-card h3 {
  font-size: 1.3rem;
  font-weight: bold;
  color: #000000;
 
  margin-bottom: 2rem;

}

.course-card p {
  font-size: 1rem;
  color: grey;
  margin-top: 1rem;
  max-width: 230px;
  font-weight: bolder;
}


.section-subtitle-home2part1 {
  font-size: 1.5rem;
  color: rgb(251, 142, 129);
  margin-bottom: 2rem;
  text-align: center;
}


.course-buttons-home2 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the buttons */
  gap: 1.5rem;
}

.course-button {
  padding: 1rem 3rem;
  border-radius: 50px;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.course1 {
  background-color: rgb(138, 136, 136);
  color: rgb(97, 97, 97);
}

.course2 {
  background-color: rgb(97, 97, 97);
  color: white;
}

.course3 {
  background-color: rgb(70, 70, 70);
  color: white;
}

.course-button:hover {
  background-color: grey;
}


/* SVG Section */
.svg-section-2-home2 {
  width: 100%;
  height: auto;
  display: block;
  margin-top: -4px;
}

/* Responsive layout for smaller screens */
@media (max-width: 1024px) {
  .content-wrapper-home2 {
      flex-direction: column; /* Stacks content and image on top of each other */
      align-items: center;
      text-align: center;
  }

  .content-container-home2 {
      padding-right: 0;
      margin-bottom: 2rem;
  }

  .section-title222-home2,
  .section-subtitle-home2 {
      text-align: center;
  }

  .course-buttons-home2 {
      align-items: center;
  }
}

@media (max-width: 768px) {
  .section-title222-home2 {
      font-size: 2.5rem;
  }

  .section-subtitle-home2 {
      font-size: 1.2rem;
  }

  .course-button {
      font-size: 1rem;
      padding: 1rem 2rem;
  }
}

@media (max-width: 480px) {
  .section-title222-home2 {
      font-size: 2rem;
  }

  .section-subtitle-home2 {
      font-size: 1rem;
  }

  .course-button {
      font-size: 0.875rem;
      padding: 0.75rem 1.5rem;
  }
}






/* Third Section: Manager's Account */

.section-3-home2 {
  background:white;
  margin-bottom: 3rem;
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(
    -30deg,  /* Angle */
    #ffffff 40%,  /* White section */
    #fdf1e9 40%, /* Light blue section start */
    #fdf1e9 60%, /* Light blue section end */
    #ffffff 60%  /* Back to white */
  );
}

.manager-content-container-home2 {
  text-align: center;
  color: black;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title-manager-home2 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: black !important;
  text-align: left;
  font-weight: 500;
}

/* Subtitle boxes layout */
.ManagerSubtitles-home2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows items to wrap when the page gets smaller */
  gap: 2rem;
  margin-bottom: 2rem;
  max-width: 900px; /* Ensures three boxes per row on large screens */
  margin-left: auto;
  margin-right: auto;
}

.subtitle-box-home2 {
  background-color: #d32f2f;
  color: white;
  padding: 5px 2px; /* Increased top and bottom padding */
  border-radius: 10px;
  width: calc(30% - 1rem); /* Reduced width for a more compact look */
  text-align: center;

  font-weight: bold;
}

.subtitle-box-home2 p {
  margin: 0;
  font-size: 1.1rem; /* Slightly increased text size */
}

/* Manager feature layout with two boxes per row */
/* Manager feature layout similar to the reference image */
.manager-features-home2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.feature-home2 {
  background-color: white; /* White background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 2rem; /* Padding to match the reference */
  width: calc(50% - 1rem); /* Two per row */
  min-width: 100px; /* Ensure it scales on smaller screens */
  text-align: left; /* Align text to the left */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; /* For image positioning */
  max-height: 900px;
}

.feature-home2 h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 1rem;
}

.feature-home2 p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

/* Image at the bottom */
.feature-image {
  width: 100%;
  height: 200px; /* Adjust as needed */
  object-fit: cover;
  border-radius: 0 0 12px 12px;
}

.feature-home2 p {
  font-size: 1rem;
  color: rgb(57, 57, 57);
}

.ManagerAccountButtonHomePage-home2 {
  padding: 15px 30px;
  background-color: #d32f2f;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  margin-top: 2rem;
}

.ManagerAccountButtonHomePage-home2:hover {
  background-color: #b71c1c;
  transform: translateY(-3px);
}

.button-container-home2 {
  text-align: center;
  margin-top: 2rem;
}

.manager-account-video-container {
  margin: 2rem 0;
  text-align: center;
}

.manager-account-video {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  opacity: 0.5;
}


/* Responsive layout for subtitle boxes and features */
@media (min-width: 1024px) {
  /* Ensures three subtitle boxes per row on larger screens */
  .ManagerSubtitles-home2 {
      max-width: 900px; /* Allows three boxes to fit side by side */
      margin-left: auto;
      margin-right: auto;
  }
}

@media (max-width: 1024px) {
  /* Stacks the subtitle boxes into two rows on medium screens */
  .ManagerSubtitles-home2 {
      max-width: 600px; /* Allows two boxes per row */
  }

  /* Two features per row on medium screens */
  .feature-home2 {
      max-width: calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  /* Stacks the subtitle boxes vertically and centers them on smaller screens */
  .ManagerSubtitles-home2 {
      flex-direction: column;
      align-items: center; /* Centers the boxes */
      max-width: 100%;
  }

  .subtitle-box-home2 {
      width: 80%; /* Make the boxes narrower on small screens */
      padding: 40px 20px; /* Increase top and bottom padding */
  }

  .feature-home2 {
      flex: 1 1 100%; /* Stacks features vertically on small screens */
  }

  .feature-home2 h3 {
      font-size: 1.3rem;
  }

  .feature-home2 p {
      font-size: 0.9rem;
  }

  .section-title-manager-home2 {
      font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .subtitle-box-home2 {
      width: 90%; /* Make the boxes more compact on very small screens */
      padding: 50px 20px; /* Further increase padding for a taller look */
  }

  .ManagerAccountButtonHomePage-home2 {
      padding: 12px 25px;
      font-size: 1rem;
  }

  .section-title-manager-home2 {
      font-size: 1.5rem;
  }

  .feature-home2 h3 {
      font-size: 1.1rem;
  }

  .feature-home2 p {
      font-size: 0.85rem;
  }
}




/* Adjust SVG Section for above the fourth section */
.svg-section-4-home2 {
  width: 100%;
  height: auto;
  display: block;
  margin-top: -4px; /* Ensures smooth transition between sections */
  background-color: #005f73; /* Fill the gap with the desired color */

}

/* Fourth Section: Standard Account */
.section4-home2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  width: 100%;
  background: linear-gradient(
    30deg,
    #ffffff 40%, 
    #fdf1e9 40%, 
    #fdf1e9 60%, 
    #ffffff 60%
  );
}

/* Responsive Wrapper */
.content-wrapper-section4-home2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem; /* Added spacing for responsiveness */
}

/* Text Content */
.content-container-section4-home2 {
  flex: 1;
  text-align: left;
  padding: 2rem;
  max-width: 600px;
}

/* Headings */
.section-title22-section4-home2 {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
  font-weight: 500;
}

/* List Styling for Accessibility */
.course-summary {
  list-style: none;
  padding-left: 0;
  font-size: 1rem;
  color: #555;
  line-height: 1.8;
}

.course-summary li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}


/* Call to Action Button */
.cta-button {
  display: inline-block;
  padding: 8px 22px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: #d32f2f;
  border-radius: 8px;
  text-decoration: none;
  margin-top: 1.5rem;
  transition: background 0.3s ease-in-out;
  text-align: center;
  width: fit-content;
}

.cta-button:hover, .cta-button:focus {
  background-color: #b71c1c;
  outline: 3px solid #b71c1c;
}

/* Image Container */
.image-container-signup {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

}

.signup-image {
  max-width: 80%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid white;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .content-wrapper-section4-home2 {
    flex-direction: column;
    text-align: center;
  }

  .content-container-section4-home2 {
    max-width: 100%;
    padding: 2rem 1rem;
  }

  .image-container-signup {
    margin-top: 2rem;
  }

  .section-title22-section4-home2 {
    font-size: 1.8rem;
  }

  .cta-button {
    width: 100%;
    max-width: 280px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .section4-home2 {
    padding: 3rem 1rem;
  }

  .section-title22-section4-home2 {
    font-size: 1.6rem;
  }

  .course-summary {
    font-size: 0.95rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .section-title22-section4-home2 {
    font-size: 1.5rem;
  }

  .course-summary {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 10px 18px;
  }
}

.section {
  padding: 2rem 1rem; /* Reduced padding */
}

.section-2-home2new,
.section-3-home2,
.section4-home2 {
  padding: 0.3rem 1rem; /* Reduce space between sections */
}


