:root {
  --primary-color: #005f73;
  --hover-color: #0a9396;
  --light-background: #f4f4f9;
  --text-color: #ffffff;
  --border-color: #ccc;
  --salmon-color: salmon;
}

.introduction-container-intro2 {
  padding: 1rem;
  background: white;

  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 1rem auto;
  max-width: 1000px;
  text-align: left;
  width: 90%; /* Make sure it scales for different screen sizes */
}

.story-introduction {
  margin-bottom: 2rem;
}

.story-introduction h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  text-align: center;
}

.cybersecurity-basics-header h1 {
  color: black;
  font-size: 2.5rem;
  font-weight: 500;
}

.video-container {
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.audio-controls {
  text-align: center;
  margin: 1rem 0;
}

.audio-controls button {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.audio-controls button:hover {
  background-color: var(--hover-color);
}

/* Custom controls */
.custom-controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.custom-controls button {
  background-color: black;
  color: white;
  border: none;
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
}

.custom-controls button:hover {
  background-color: #444;
}

/* Progress bar */
.progress-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e0e0e0;
}

.progress-bar {
  height: 5px;
  background-color: var(--primary-color);
  width: 0%;
}

/* Start button */
.start-button-container-2 {
  text-align: center;
  margin-top: 1rem;
}

.start-button-course2 {
  width: 100%;
  max-width: 300px; /* Limit button size on larger screens */
  padding: 1rem;
  background-color: rgb(201, 3, 3);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: background-color 0.3s ease;
}

.start-button-course2:hover {
  background-color: rgb(218, 70, 70);
}

/* Back button */
.back-button {
  display: block;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: var(--hover-color);
}

.introduction-content {
  display: flex;
  align-items: center;
  border: 2px solid black;
  background-color: var(--primary-color);
  border-radius: 8px;
  padding: 1rem;
}

.introduction-image {
  width: 50%;
  height: auto;
  border-radius: 8px;
}

.introduction-text {
  margin-left: 1rem;
  color: white;
  font-size: 1.2rem;
}

.introduction-text2 {
  margin-left: 1rem;
  color: salmon;
  font-size: 2rem;
}

.introduction-text p {
  margin-bottom: 2rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .introduction-container-intro2 {
    width: 95%; /* More compact for tablet screens */
  }

  .start-button-course2 {
    font-size: 1rem;
    padding: 0.75rem;
    max-width: 100%; /* Let the button take up full width on smaller screens */
  }

  .back-button {
    width: 100%; /* Make the back button full width */
    max-width: 300px;
  }

  .introduction-content {
    flex-direction: column; /* Stack content vertically on smaller screens */
  }

  .introduction-image {
    width: 100%; /* Image takes full width */
    margin-bottom: 1rem;
  }

  .introduction-text {
    margin-left: 0; /* Remove margin on small screens */
    text-align: center; /* Center the text */
  }
}

@media (max-width: 480px) {
  .story-introduction h2 {
    font-size: 1.5rem;
  }

  .start-button-course2 {
    font-size: 0.875rem;
  }

  .back-button1 {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }

  .introduction-content {
    padding: 0.5rem; /* Reduce padding on mobile */
  }

  .introduction-text {
    font-size: 1rem; /* Smaller text for mobile */
  }

  .introduction-text2 {
    font-size: 1.5rem; /* Adjust the large text for smaller screens */
  }
}
