.poster-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #e0f7fa;
  border: 1px solid #00838f;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
  position: relative; /* Added to allow positioning of back button */
}

h1, h2 {
  color: #006064;
}

.poster-content section {
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.poster-content h2 {
  margin-top: 0;
  color: #004d40;
}

.poster-content p, .poster-content ul {
  color: #004d40;
  text-align: left;
}

.poster-content ul {
  padding-left: 20px;
}

.poster-content li {
  margin-bottom: 10px;
}

.poster-content li strong {
  color: #00796b;
}

.poster-image {
  width: 60%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

.button-container.top {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.nav-button.back-button {
  position: absolute; /* Added to position the button */
  top: 20px; /* Adjust to desired position */
  left: 20px; /* Adjust to desired position */
  padding: 10px 20px;
  background-color: #00796b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-button.back-button:hover {
  background-color: #004d40;
}

.button-container.bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.nav-button.next-button {
  padding: 10px 20px;
  background-color: #00796b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-button.next-button:hover {
  background-color: #004d40;
}
