/* EmailVerification.css */

.verification-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: aliceblue;
    padding: 20px;
    box-sizing: border-box;
    background: linear-gradient(
    -30deg,  /* Angle */
    #ffffff 40%,  /* White section */
    #fdf1e9 40%, /* Light blue section start */
    #fdf1e9 60%, /* Light blue section end */
    #ffffff 60%  /* Back to white */
  );
  }
  
  .verification-container h1 {
    color: black;
    font-size: 2rem;
    margin-bottom: 20px !important;
  }
  
  .verification-container p {
    font-size: 1.2rem;
    color: #555;
    text-align: center;
    max-width: 800px;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }


  .verification-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: aliceblue;
    padding: 20px;
    box-sizing: border-box;
    background: linear-gradient(
    -30deg,  /* Angle */
    #ffffff 40%,  /* White section */
    #fdf1e9 40%, /* Light blue section start */
    #fdf1e9 60%, /* Light blue section end */
    #ffffff 60%  /* Back to white */
  );
  }
  
  .verification-container2 h1 {
    color: black;
    font-size: 2rem;
    margin-bottom: 20px !important;
  }

  .verification-container2 p {
    font-size: 1.2rem;
    color: #555;
    text-align: center;
    max-width: 800px;
    background-color: #000000;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
  
  /* Add a subtle animation to the verification message */
  .verification-container p {
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .verification-container h1 {
      font-size: 1.5rem;
    }
  
    .verification-container p {
      font-size: 1rem;
      padding: 10px;
    }
  }
  
  /* Typography settings for a cleaner look */
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: white;
  }
  
  .verification-button {
    padding: 1.2rem 1.8rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: background-color 0.3s ease;
    margin-bottom: 1rem;
  }

  .resend-button {
    padding: 1.2rem 1.8rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: background-color 0.3s ease;
    margin-bottom: 2rem;
    margin-left: 2rem;
  }
  .verification-button:hover,
.resend-button:hover {
    background-color: rgb(67, 67, 67);
    color: white; /* Optional: Ensures text is visible on black background */
}
