/* src/components/CookiePolicy.css */
.cookie-policy-container {
  width: 80%;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f4f4f9;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cookie-policy-container h1 {
  margin-bottom: 20px !important;
}

.cookie-policy-container h1,
.cookie-policy-container h2,
.cookie-policy-container h3 {
  color: #005f73;
  margin-bottom: 1rem;
  text-align: left;
}

.cookie-policy-container p,
.cookie-policy-container ul {
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.cookie-policy-container ul {
  padding-left: 1.5rem;
}

.cookie-policy-container li {
  margin-bottom: 1rem;
}

.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-buttons button {
  padding: 0.5rem 1rem;
  background-color: #005f73;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button:hover {
  background-color: #0a9396;
}
