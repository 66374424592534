:root {
  --primary-color: #005f73;
  --hover-color: #0a9396;
  --light-background: #f4f4f9;
  --text-color: white;
  --border-color: #ccc;
}

/* General container styling */
.cybersecurity-basics-container {
  padding: 1rem;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 1rem auto;
  max-width: 1000px;
  width: 90%; /* Make sure it scales for different screen sizes */
  text-align: left;
  position: relative;
}

/* Header styling */
.cybersecurity-basics-header {
  text-align: center;
  margin-bottom: 1rem;
}

.cybersecurity-basics-header h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

/* Video container */
.video-container {
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: auto;
  max-width: 100%;
}

/* Module content */
.module-section {
  margin-bottom: 1rem;
}

.module-content h3 {
  font-size: 1.5rem;
  color: salmon;
}

.module-content p {
  font-size: 1rem;
  color: black;
  margin-bottom: 0rem;
}

/* Highlighted section */
.highlighted-section {
  border: 4px solid salmon;
  padding: 0.75rem;
  border-radius: 8px;
  margin-top: 0.75rem;
  background-color: var(--primary-color);
  color: var(--text-color);
}

.highlighted-section h3 {
  color: white;
}

/* Start quiz button */
.start-quiz-button-container {
  text-align: center;
  margin-top: 1rem;
}

.start-quiz-button {
  display: inline-block;
  width: 100%;
  max-width: 300px; /* Limit button size on larger screens */
  padding: 0.75rem;
  background-color: rgb(201, 3, 3);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: background-color 0.3s ease;
}

.start-quiz-button:hover {
  background-color: rgb(218, 70, 70);
}

.warning-text {
  color: rgb(172, 0, 0);
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
}

/* Back button */
.back-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.back-button {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: var(--hover-color);
}

/* Responsive behavior */
@media (max-width: 768px) {
  .cybersecurity-basics-container {
    width: 95%; /* Slightly more compact for tablets */
  }

  .module-content h3 {
    font-size: 1.25rem; /* Slightly smaller for tablets */
  }

  .start-quiz-button {
    font-size: 1rem; /* Slightly smaller button text */
  }

  .back-button {
    width: 100%;
    max-width: 300px; /* Make back button full-width on smaller screens */
  }
}

@media (max-width: 480px) {
  .cybersecurity-basics-header h2 {
    font-size: 1.5rem; /* Reduce title size for mobile */
  }

  .module-content h3 {
    font-size: 1.15rem; /* Smaller headings on mobile */
  }

  .module-content p {
    font-size: 0.9rem; /* Smaller text for mobile */
  }

  .start-quiz-button {
    font-size: 0.9rem; /* Smaller button text */
    padding: 0.5rem;
  }

  .back-button {
    font-size: 0.9rem; /* Smaller back button */
  }

  .highlighted-section h3 {
    font-size: 1rem;
  }
}

/* Flip Card Container */
.flip-card-M2C2 {
  background-color: transparent;
  width: 400px;
  height: 250px;
  perspective: 1000px;
  margin: 1rem auto;
  cursor: pointer;
  margin-top: 3rem;
}

/* Inner container for the flip effect */
.flip-card-M2C2-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

/* Apply flip when the card has the flip-card-M2C2-flipped class */
.flip-card-M2C2.flip-card-M2C2-flipped .flip-card-M2C2-inner {
  transform: rotateY(180deg);
}

/* Front and back faces of the card */
.flip-card-M2C2-front, 
.flip-card-M2C2-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  text-align: left;
}

/* Front face style */
.flip-card-M2C2-front {
  background-color: #fff;
  color: #000;
}

/* Back face style and rotated */
.flip-card-M2C2-back {
  background-color: #f8f8f8;
  color: #000;
  transform: rotateY(180deg);
}

/* Style for the "Next Card" button */
/* In your CSS file (e.g. WhatIsCyberSecurity.css) */
.next-card-button-container {
  text-align: center;
  margin-top: 1rem; /* optional spacing above the button */
}

.next-card-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.nav-card-button-container {
  display: flex;
  justify-content: center; /* center horizontally */
  gap: 1rem; /* optional spacing between buttons */
  margin-top: 1.5rem; /* optional spacing above the button */
}

.nav-card-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.start-quiz-button-container {
  text-align: center;
  margin-top: 1rem;
}

/* Card styling for the entire scenario container */
.scenario-container {
  background-color: #edecec;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 2rem auto;
  max-width: 800px;
}

/* Header styling for the scenario title */
.scenario-container h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
}

/* Styling for the scenario step content */
.scenario-step {
  border-top: 1px solid #e0e0e0;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.scenario-step h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #007BFF;
}

.scenario-step p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Container for the navigation arrows */
.scenario-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

/* Unique arrow button styling (C2M3) */
.scenario-nav-button-C2M3 {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #007BFF;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.scenario-nav-button-C2M3:hover {
  color: #0056b3;
}

.scenario-nav-button-C2M3:focus {
  outline: none;
}

/* Spacing between adjacent arrow buttons */
.scenario-navigation button.scenario-nav-button-C2M3 + button.scenario-nav-button-C2M3 {
  margin-left: 1rem;
}

/* Styling for the completion message */
.scenario-complete-message {
  font-size: 1rem;
  color: #227836;
  font-weight: bold;
  margin: 0;
  padding: 0.5rem 1rem;
  text-align: center;
}





/* Style for the explanation card that appears after answering */
.explanation-card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1.5rem;
  text-align: left;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.explanation-card h4 {
  margin-top: 0;
  color: #0258b3;
  margin-bottom: 1rem;
}

.explanation-card.correct {
  border-color: #1c6a2e;
}

.explanation-card.incorrect {
  border-color: #a9212f;
}

/* Disable the Start Quiz button until answered */
.start-quiz-button1:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
