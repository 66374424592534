/* General Container */
.ThreatStats-container {
    padding: 40px;
    background: white; 
    border-radius: 10px;
    max-width: 850px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid var(--border-color);

}

/* Chart Container */
.ThreatStats-chart-container {
    margin-bottom: 60px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 400px; /* Ensures all charts are the same height */
    border: 2px solid #dcdcdc;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers the chart inside the container */
    align-items: center;
    
}

/* Title Styling */
.ThreatStats-title {
    text-align: center;
    font-size: 1.8rem;
    color: black;
    margin-bottom: 20px;
    font-weight: 500;
}



/* Pie Chart Container */
.ThreatStats-pie-container {
    margin-bottom: 60px; /* Increased space for the pie chart */
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 400px;
    height: 400px; /* Ensures the pie chart matches the bar charts */
    border: 2px solid #dcdcdc; /* Border around the pie chart */
}

/* Horizontal Line Between Charts */
.ThreatStats-divider {
    margin: 50px 0; /* Increased margin to add more space between sections */
    border-top: 1px solid #dcdcdc;
}

/* Monthly Charts Container */
.ThreatStats-monthly-charts .ThreatStats-chart-container {
    margin-bottom: 120px; /* Doubled the space between the bottom three graphs */
}

.ThreatStats-monthly-charts h2 {
    color: black;
    margin-bottom: 20px;
} 

/* Heading Styles */
.ThreatStats-container h1 {
    font-size: 2.5rem;
    color: black;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
}

.ThreatStats-chart-container h2,
.ThreatStats-pie-container h2 {
    font-size: 1.8rem;
    color: #34495e;
    margin-bottom: 20px;
    text-align: center;
}

/* Chart Styling */
canvas {
    max-height: 100%;
    height: 100%;
    width: 100%; /* Ensure the charts use full container width */
}

/* Responsive Design */
@media (max-width: 768px) {
    .ThreatStats-container {
        padding: 20px;
    }

    .ThreatStats-chart-container h2,
    .ThreatStats-pie-container h2 {
        font-size: 1.5rem;
    }

    .ThreatStats-chart-container,
    .ThreatStats-pie-container {
        max-height: 300px;
        height: 300px; /* Adjust height for smaller screens */
    }
}

@media (max-width: 576px) {
    .ThreatStats-container h1 {
        font-size: 2rem;
    }

    .ThreatStats-chart-container h2,
    .ThreatStats-pie-container h2 {
        font-size: 1.3rem;
    }

    .ThreatStats-chart-container,
    .ThreatStats-pie-container {
        max-height: 250px;
        height: 250px; /* Further adjust height for very small screens */
    }
}
